import { useNavigate } from "react-router-dom";

import { language } from "../../language/Language";

import { Link } from "react-router-dom";
import "./Footer.css";
import footer1 from "../../assets/footer1.svg";
import footer2 from "../../assets/footer2.svg";
import footer3 from "../../assets/footer3.svg";
import footer4 from "../../assets/footer4.svg";

const Footer = () => {
  const route = useNavigate();

  return (
    <footer
      className="position-relative"
      style={{ backgroundColor: "#031B59", color: "white" }}
    >
      <div className="container">
        <div className="row">
          <div className="position-absolute footer-pos d-flex">
            <h3>
              {language === "en"
                ? "Industrial Zone, Abu Rawash, Giza."
                : "المنطقة الصناعية, ابو رواش, الجيزة."}
            </h3>
            <h3>0225760958</h3>
            <h3>01005147716</h3>
            <h3>info@elmotahda.com</h3>
          </div>
          <div className="col-6 col-lg-3">
            <ul className="d-flex flex-nowrap m-0 gap-3 justify-content-center justify-content-lg-start">
              <li>
                <Link to={""}>
                  <img src={footer1} style={{ width: "50px" }} alt="footer1" />
                </Link>
              </li>
              <li>
                <Link to={""}>
                  <img src={footer2} style={{ width: "40px" }} alt="footer2" />
                </Link>
              </li>
            </ul>

            <ul className="d-flex flex-column align-items-center align-items-lg-start justify-content-center">
              <li>
                <Link to={""}>{language === "en" ? "about us" : "من نحن"}</Link>
              </li>
              <li>
                <Link to={"/Connect"}>
                  {" "}
                  {language === "en" ? "Contact us" : "تواصل معنا"}
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 map d-none d-lg-flex">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d215.8184329659354!2d31.034569301099307!3d30.06281335061826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14585b0a88e21cd7%3A0x96b6ac534b0d8a70!2z2KfZhNi02LHZg9ipINin2YTZhdiq2K3Yr9ipINmE2YTYp9mD2YrYp9izIC0g2YrZiNmG2YrYqtivINio2KfZgw!5e0!3m2!1sar!2seg!4v1726932256111!5m2!1sar!2seg"
              height="200"
              style={{ border: 0, width: "100%" }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
          <div className="col-6 col-lg-3">
            <ul className="d-flex flex-nowrap m-0 gap-3 justify-content-center justify-content-lg-start">
              <li>
                <Link to={""}>
                  <img src={footer3} style={{ width: "40px" }} alt="footer3" />
                </Link>
              </li>
              <li>
                <Link to={""}>
                  <img src={footer4} style={{ width: "40px" }} alt="footer4" />
                </Link>
              </li>
            </ul>
            <ul className="d-flex flex-column align-items-center align-items-lg-start justify-content-center">
              <li>
                <Link to={"/Products"}>
                  {language === "en" ? "Top Products" : "اهم المنتجات"}
                </Link>
              </li>
              <li>
                <Link to={""}>{language === "en" ? "Articles" : "مقالات"}</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 my-4 map d-flex d-lg-none">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d215.8184329659354!2d31.034569301099307!3d30.06281335061826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14585b0a88e21cd7%3A0x96b6ac534b0d8a70!2z2KfZhNi02LHZg9ipINin2YTZhdiq2K3Yr9ipINmE2YTYp9mD2YrYp9izIC0g2YrZiNmG2YrYqtivINio2KfZgw!5e0!3m2!1sar!2seg!4v1726932256111!5m2!1sar!2seg"
              height="200"
              style={{ border: 0, width: "100%" }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
          <div className="col d-flex d-lg-none justify-content-center align-items-center gap-3 flex-column">
            <h3>
              {language === "en"
                ? "Industrial Zone, Abu Rawash, Giza."
                : "المنطقة الصناعية, ابو رواش, الجيزة."}
            </h3>
            <h3>0225760958</h3>
            <h3>01005147716</h3>
            <h3>info@elmotahda.com</h3>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
