import React, { useState } from "react";
import { DeleteFilled } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Flex,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Upload,
  message,
  Spin,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  useDeleteSliderMutation,
  useGetSlidesQuery,
  useUploadSliderMutation,
} from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import { ExclamationCircleFilled } from "@ant-design/icons";

const { Meta } = Card;
const { confirm } = Modal;

function AddSlider() {
  const [OpenSliderModal, setOpenSliderModal] = useState(false);
  const [modalKey, setModalKey] = useState(0);
  const [title, setTitle] = useState("");
  const [titleEN, setTitleEN] = useState("");
  const [uploadSlider, { isLoading, isError, error }] =
    useUploadSliderMutation();
  const [
    deleteSlider,
    { isLoading: delIsLoading, isError: delIsError, error: delError },
  ] = useDeleteSliderMutation();

  const handleAddProduct = async () => {
    if (!title) {
      message.error("Please insert a title");
      return;
    }
    if (!titleEN) {
      message.error("Please insert an english title");
      return;
    }
    const formData = new FormData();

    formData.append("title", title);
    formData.append("title_en", titleEN);

    try {
      const res = await uploadSlider(formData);

      setTitle("");
      setTitleEN("");
      setModalKey((prev) => prev + 1);
      message.success("Slider added successfully");
      setOpenSliderModal(false);
    } catch (error) {
      console.error("Error adding product:", error);
      message.error("Error adding slider. Please try again.");
    }
  };

  const {
    data: allSlidesData,
    isError: isSlidesError,
    isLoading: isSlidesLoading,
    error: slidesError,
  } = useGetSlidesQuery();
  const allSlides = allSlidesData?.data ?? null;

  const handleDeleteSlider = async (id) => {
    confirm({
      title: "Do you really want to delete this Slider title?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, this Slider title will be deleted",
      async onOk() {
        try {
          const res = await deleteSlider(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("slider title is deleted successfully");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };

  return (
    <div className=" ">
      <h2 className="main_heading mb-4">Slider Titles</h2>
      {(delIsError || isSlidesError) && (
        <p className="color-danger">Something went wrong</p>
      )}
      <Flex gap="small" wrap="nowrap" className="justify-content-center mb-5">
        <Button type="primary" onClick={() => setOpenSliderModal(true)}>
          <FontAwesomeIcon icon={faPlus} /> Add a Slider Title{" "}
        </Button>
      </Flex>

      <div className="cards d-flex flex-wrap gap-4 justify-content-center position-relative">
        {isSlidesLoading && <Loading />}
        {allSlides?.map((slide) => (
          <Card
            key={slide.id}
            hoverable
            className="col-3"
            loading={delIsLoading}
            actions={[
              <DeleteFilled
                key="setting"
                size="large"
                onClick={() => {
                  handleDeleteSlider(slide.id);
                }}
              />,
              //   <Switch defaultChecked />
            ]}
          >
            <Meta title={slide.title} />
            <div className=" position-relative"></div>
          </Card>
        ))}
      </div>

      {/* add slider */}
      <Modal
        title="Add Slider Title"
        centered
        open={OpenSliderModal}
        onCancel={() => setOpenSliderModal(false)}
        width={500}
        footer=""
        key={modalKey}
      >
        <div className="d-flex flex-column flex-wrap justify-content-between gap-2">
          <Row>
            <Col md className="col-12 d-flex flex-column gap-2">
              <Input
                size="large"
                placeholder="arabic title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <Input
                size="large"
                placeholder="english title"
                value={titleEN}
                onChange={(e) => setTitleEN(e.target.value)}
              />
            </Col>
          </Row>

          <Row className="g-2">
            <Button
              type="primary"
              onClick={handleAddProduct}
              loading={isLoading}
            >
              Add title
            </Button>
          </Row>
        </div>
      </Modal>
    </div>
  );
}

export default AddSlider;
