import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Modal, Space, Upload, message, Button, DatePicker, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useAddArticleMutation } from "../../redux/Service/api";
import TextArea from "antd/es/input/TextArea";

const { RangePicker } = DatePicker;
const AddArticle = ({ setOpenAddModal, openAddModal }) => {
  const [modalKey, setModalKey] = useState(0);

  const [authorName, setAuthorName] = useState("");
  const [authorNameEN, setAuthorNameEN] = useState("");
  const [content, setContent] = useState("");
  const [contentEN, setContentEN] = useState("");
  const [title, setTitle] = useState("");
  const [titleEN, setTitleEN] = useState("");

  const [file, setFile] = useState(null);

  const [addArticle, { isLoading, isError, error }] = useAddArticleMutation();

  const handleFileChange = (fileList) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const handleAddOffer = async () => {
    if (!file) {
      message.error("Please upload the file");
      return;
    }
    if (!title) {
      message.error("Please upload the title");
      return;
    }
    if (!content) {
      message.error("Please upload the content");
      return;
    }
    if (!authorName) {
      message.error("Please upload the author Name");
      return;
    }
    if (!titleEN) {
      message.error("Please upload the english title");
      return;
    }
    if (!contentEN) {
      message.error("Please upload the english content");
      return;
    }
    if (!authorNameEN) {
      message.error("Please upload english author Name");
      return;
    }

    const formData = new FormData();

    formData.append("photo", file);

    formData.append("title", title);
    formData.append("title_en", titleEN);
    formData.append("author", authorName);
    formData.append("author_en", authorNameEN);
    formData.append("content", content);
    formData.append("content_en", contentEN);

    try {
      const res = await addArticle(formData);
      setFile(null);
      setAuthorName("");
      setAuthorNameEN("");
      setTitle("");
      setTitleEN("");
      setContent("");
      setContentEN("");

      setModalKey((prev) => prev + 1);
      res.data.message
        ? message.info(res.data.message)
        : message.success("Offer added successfully");
      setOpenAddModal(false);
    } catch (error) {
      console.error("Error adding offer:", error);
      message.error("Error adding offer. Please try again.");
    }
  };

  return (
    <Modal
      key={modalKey}
      title="Add Article"
      centered
      open={openAddModal}
      onCancel={() => setOpenAddModal(false)}
      width={1000}
      footer
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size="large"
        >
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column">
              <Input
                placeholder="عنوان المقالة"
                style={{ direction: "rtl", textAlign: "right" }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <Input
                placeholder="اسم المؤلف"
                style={{ direction: "rtl", textAlign: "right" }}
                value={authorName}
                onChange={(e) => setAuthorName(e.target.value)}
              />

              <TextArea
                rows={6}
                placeholder="الوصف"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                style={{ direction: "rtl", textAlign: "right" }}
              />
            </Col>
            <Col className="gap-3 d-flex flex-column">
              <Input
                placeholder="Title in english"
                value={titleEN}
                onChange={(e) => setTitleEN(e.target.value)}
              />
              <Input
                placeholder="author name in english"
                value={authorNameEN}
                onChange={(e) => setAuthorNameEN(e.target.value)}
              />

              <TextArea
                rows={6}
                placeholder="Content in english"
                value={contentEN}
                onChange={(e) => setContentEN(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column col-4">
              <Upload
                customRequest={({ file, onSuccess, onError }) => {
                  try {
                    onSuccess();
                  } catch (error) {
                    console.error("Error handling file:", error);
                  }
                }}
                onChange={({ fileList }) => {
                  handleFileChange(fileList);
                }}
                listType="picture"
                maxCount={1}
              >
                <Button
                  loading={false}
                  icon={<FontAwesomeIcon icon={faDownload} />}
                >
                  Upload Image (Max: 1)
                </Button>
              </Upload>
            </Col>
          </Row>
          <Row className="g-2">
            <Button type="primary" onClick={handleAddOffer} loading={isLoading}>
              Add the article
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default AddArticle;
