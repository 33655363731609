import icon10 from "../../assets/icon10.svg";
import icon11 from "../../assets/icon11.svg";
import icon12 from "../../assets/icon12.svg";
import { language } from "../../language/Language";
function NavbarTop() {
  return (
    <div className="" style={{ backgroundColor: "#162FB0" }}>
      <div className="container d-flex justify-content-between align-items-center">
        <h3 className="title-wedsite" style={{ color: "white" }}>
          {language === "en"
            ? "United Pack Company"
            : "الشركة المتحدة للأكياس يونيتد باك"}
        </h3>
        <div className="">
          <img src={icon10} style={{ width: "50px" }} alt="icon10" />
          <img src={icon11} style={{ width: "50px" }} alt="icon10" />
          <img src={icon12} style={{ width: "50px" }} alt="icon10" />
        </div>
      </div>
    </div>
  );
}

export default NavbarTop;
