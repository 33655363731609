import { Spin } from "antd";
import React from "react";

const Loading = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        maxHeight: "100vh",
        backgroundColor: "#00000020",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "1rem",
        zIndex: 4,
      }}
    >
      <Spin size="large" style={{ zIndex: 5 }} />
    </div>
  );
};

export default Loading;
