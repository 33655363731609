import { Link, NavLink, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useRef, useEffect, useState, useLayoutEffect } from "react";
import { motion } from "framer-motion";
// import logo from "../../assets/logo.jpg";
import logo from "../../assets/sargLogo.png";

import "./Navbar.css";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { IoIosGlobe } from "react-icons/io";
import { language } from "../../language/Language";
import { Switch } from "antd";
import { ToDarkMode, ToLightMode } from "../../redux/Slices/DarkModeSlice";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((st) => st.auth);
  const { mode } = useSelector((st) => st.DarkMode);
  const [darkSwitchValue, setDarkSwitchValue] = useState(mode);

  const [mobileMode, setMobileMode] = useState(false);

  let isHeld = false;
  const headerref = useRef();
  const toggleref = useRef();
  const navref = useRef();
  const [tapStartTime, setTapStartTime] = useState(null);

  const stopPropa = (e) => {
    e.stopPropagation();
  };
  const handleclassopen = (...ele) => {
    ele.forEach((element) => {
      element.current.classList.toggle("open");
    });
  };

  const handlenav = (e) => {
    stopPropa(e);
    handleclassopen(toggleref, navref);
    setMobileMode((prev) => !prev);
  };

  const handleTapStart = () => {
    setTapStartTime(Date.now());
  };

  const handleTap = () => {
    clearTimeout(timeoutId);

    const tapDuration = Date.now() - tapStartTime;
    console.log(tapDuration);

    if (tapDuration >= 1500 && tapDuration < 5000) {
      isAuthenticated ? navigate("/dashboard") : navigate("/login");
    } else {
      navigate("/");
    }
  };

  let timeoutId;

  console.log(mode);

  const darkModeSwitchChange = (value) => {
    setDarkSwitchValue(value);

    if (value) {
      dispatch(ToDarkMode());
    } else {
      dispatch(ToLightMode());
    }
  };
  useLayoutEffect(() => {
    setDarkSwitchValue(mode);
    if (mode) {
      document.body.classList.add("dark-mode");
      localStorage.setItem("unitedDarkMode", true);
    } else {
      document.body.classList.remove("dark-mode");
      localStorage.setItem("unitedDarkMode", false);
    }
  }, [mode]);

  return (
    <header ref={headerref} style={{ backgroundColor: "#CAC4D09E" }}>
      <Container className="d-flex align-items-center justify-content-between position-relative py-2">
        <nav className="flex_between ">
          <div className="pages mt-2 me-auto" ref={navref}>
            <NavLink className="" to="/">
              {language === "en" ? "Home" : "الصفحة الرئيسية"}
            </NavLink>{" "}
            <NavLink className="" to="/Connect">
              {language === "en" ? "Contact Us" : "تواصل معنا"}
            </NavLink>{" "}
            <NavLink className="" to="/Jobs">
              {language === "en" ? "Jobs" : "الوظائف"}
            </NavLink>{" "}
            <NavLink className="" to="/Products">
              {language === "en" ? "Products" : "المنتجات"}
            </NavLink>{" "}
            {isAuthenticated && (
              <NavLink className="" to="/dashboard">
                {language === "en" ? "Dashboard" : "لوحة التحكم"}
              </NavLink>
            )}
            <span
              style={{ display: "inline-block" }}
              className={`${mobileMode && "mx-2 mb-3"}`}
            >
              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  style={{
                    width: "51px",
                    color: "#000",
                  }}
                  className="p-0"
                >
                  <IoIosGlobe
                    color="#b2b4bc"
                    style={{ height: "1.6rem", width: "2rem" }}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="">
                  <Dropdown.Item
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage.setItem("language", "ar");
                      window.location.reload();
                    }}
                    className="d-flex flex-nowrap align-items-center justify-content-between py-2"
                    style={{ color: language == "en" ? "black" : "#b6724b" }}
                  >
                    <div className="">ar</div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage.setItem("language", "en");
                      window.location.reload();
                    }}
                    className="d-flex flex-nowrap align-items-center justify-content-between  py-2"
                    style={{ color: language == "en" ? "#b6724b" : "black" }}
                  >
                    <div>en</div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </span>
            {/* <span
              style={{ display: "inline-block" }}
              className={`${mobileMode && "mx-2 mb-3"}`}
            >
              <Switch
                checkedChildren="Dark"
                unCheckedChildren="Light"
                value={darkSwitchValue}
                onChange={darkModeSwitchChange}
              />
            </span> */}
          </div>
          <div className="info d-flex mt-2">
            <div
              className="toggle d-lg-none"
              ref={toggleref}
              onClick={handlenav}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </nav>
        <motion.span
          onTapStart={handleTapStart}
          onTap={() => {
            handleTap();
            setTapStartTime(null);
          }}
          className="logo"
          whileTap={{ scale: 0.9 }}
        >
          <img src={logo} alt="..." />
        </motion.span>
      </Container>
    </header>
  );
};
export default Navbar;
