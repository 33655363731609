import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    userData: null,
    // ... other state properties
  },
  reducers: {
    reduxLogin: (state) => {
      state.isAuthenticated = true;
    },
    reduxLogout: (state) => {
      state.isAuthenticated = false;
      state.userData = null;
    },
    saveUserData: (state, action) => {
      state.userData = action.payload;
    },
  },
});

export const { reduxLogin, reduxLogout, saveUserData } = authSlice.actions;

export default authSlice.reducer;
