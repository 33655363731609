import { Image } from "react-bootstrap";
import "./SaddleNews.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

// import logo from "../../assets/logo.jpg";
import logo from "../../assets/sargLogo.png";

import { useEffect } from "react";
import Aos from "aos";
import { useGetAllNewsQuery } from "../../redux/Service/api";
import { Row, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { language } from "../../language/Language";
// image
import icon1 from "../../assets/icon1.jpg";
import title from "../../assets/title.png";

const Testimonials = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({ duration: 2000 }); // تهيئة AOS وتحديد مدة الانتقال الافتراضية
  }, []);

  const {
    data: allNewsData,
    isError: isAllNewsError,
    isLoading: isAllNewsLoading,
    error: allNewsError,
  } = useGetAllNewsQuery();

  const allNews = allNewsData?.data ?? null;

  return (
    <>
      {/* <div
        className="aboutUs py-5 mb-2 mb-lg-5"
        data-aos="fade-up"
        // data-aos-anchor-placement="top-bottom"
      >
        <h1 className="heading gap-3 d-flex align-items-center justify-content-center">
          {language === "en" ? "Sarg news" : "اخبار سرج"}

          <Image className="col-2 rounded-5 heading" src={logo} />
        </h1>
      </div> */}
      <div className="aboutUs pt-4 pt-sm-0 mb-5 d-flex justify-content-center align-items-center gap-md-5">
        <div className="align-items-center justify-content-center p-0 image_headeing">
          <Image className="heading" src={icon1} />
        </div>
        <div className="d-flex align-items-center justify-content-center text_headeing">
          <h3
            style={{
              backgroundImage: `url(${title})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain", // لضبط الصورة لتغطية العنصر بالكامل
              backgroundPosition: "center", // لضبط موضع الصورة في المنتصف
            }}
            className="mx-auto d-flex align-items-center justify-content-center headeingText main-color-mot-red"
          >
            {language === "en" ? "Our latest news" : "اخر اخبارنا "}
          </h3>
        </div>
      </div>
      <Row className="row justify-content-center column-gap-4 sarg_news ">
        {isAllNewsLoading && <Spin />}
        {allNews?.map((news) => (
          <figure
            key={news.id}
            className="snip1208 d-flex flex-wrap new-light-bg-color col-12 pb-5 pb-md-0 news_components"
            data-aos="fade-left"
            // data-aos-anchor-placement="top-bottom"
          >
            <img src={news.photo} className="newsImg" alt="sample66" />
            <figcaption>
              {/* <span className="d-flex justify-content-end align-items-center">
                <span className="user">{news.author}</span>
              </span> */}
              <h5 className="my-2 description_title main-color-mot-red">
                {/* {news.title} */}
                {news.title.slice(0, 55).trim()}...
              </h5>
              <p className="description_news fw-bold main-color-mot">
                {/* {news.content} */}
                {news.content.slice(0, 200).trim()}...
              </p>
              <button
                className="btn-more"
                onClick={() => navigate(`/News/${news.id}`)}
              >
                {language === "en" ? "Read more" : "اقرأ المزيد"}
              </button>
            </figcaption>
          </figure>
        ))}
        {isAllNewsError && (
          <figure
            className="snip1208 col-lg-4"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            <img src={logo} alt="sample66" />

            <figcaption>
              <span className="d-flex justify-content-end align-items-center">
                <span className="user">
                  {language === "en"
                    ? "United Pack Company"
                    : "الشركة المتحدة للأكياس يونيتد باك"}
                </span>
              </span>
              <h5>
                {language === "en"
                  ? "Our ambition should be to achieve 100 billion from the industrial sector"
                  : "يجب أن يكون طموحنا تحقيق 100 مليار من قطاع الصناعة"}
              </h5>
              <p>
                {language === "en"
                  ? `The mission of United Plastic Company is to provide high-quality plastic products and services that meet the needs of its customers. The company is also committed to continuous innovation and improvement and strives to be the leading provider of plastic products in Egypt and the Middle East.`
                  : `تركز مهمة شركة المتحده للبلاستيك على توفير منتجات وخدمات بلاستيكية عالية الجودة تلبي احتياجات عملائها. كما تلتزم الشركة بالابتكار والتحسين المستمر وتسعى جاهدة لتكون المزود الرئيسي للمنتجات البلاستيكية في مصر ومنطقة الشرق الأوسط.`}
              </p>
            </figcaption>
          </figure>
        )}
      </Row>
    </>
  );
};

export default Testimonials;
