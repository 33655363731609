import { createSlice } from "@reduxjs/toolkit";

const initialState = { mode: false };

const DarkModeSlice = createSlice({
  name: "darkMode",
  initialState,
  reducers: {
    ToDarkMode: (state) => {
      state.mode = true;
      console.log("todark");
    },
    ToLightMode: (state) => {
      state.mode = false;
      console.log("tolight");
    },
  },
});

export default DarkModeSlice.reducer;

export const { ToDarkMode, ToLightMode } = DarkModeSlice.actions;
