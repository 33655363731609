// import AppTitles from "./Carousel";
// import SliderImg from "./SliderImg";

import { Swiper, SwiperSlide } from "swiper/react";
// import { Row } from 'antd'
import "swiper/css";
import "swiper/css/effect-fade";
// import "swiper/css/navigation";
import "swiper/css/pagination";
import "./styles.css";
// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import sliderC from "../../assets/sliderC.png";
import { useGetAllBannersQuery } from "../../redux/Service/api";

function Slider() {
  const {
    data: allPhotoSlidersData,
    isError: isAllPhotoSlidersError,
    isLoading: isAllPhotoSlidersLoading,
    error: allPhotoSlidersError,
  } = useGetAllBannersQuery();

  const allBanners = allPhotoSlidersData?.data ?? null;
  return (
    // <div className="d-flex flex-wrap row home-heading flex-column-reverse flex-lg-row">
    //   <AppTitles data-aos="fade-left" />
    //   <SliderImg data-aos="fade-right" />
    // </div>
    <>
      <Swiper
        spaceBetween={30}
        // effect={"fade"}
        loop={true}
        navigation={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        modules={[Autoplay, EffectFade, Pagination, Navigation]}
        className="mySwiper text-white"
      >
        {allBanners?.map((slider) => (
          <SwiperSlide className="position-relative" key={slider.id}>
            <img src={slider.photo} alt="" />
            {slider.title && (
              <h3 className="title-slide position-absolute top-50 left-50 d-block d-md-block">
                {slider.title}
              </h3>
            )}
          </SwiperSlide>
        ))}

        {/* <SwiperSlide className="position-relative">
          <img src={sliderC} alt="" />
          <h3 className="position-absolute top-50 left-50 fs-1 d-none d-md-block">
            جوده الحاضر ......... وإسعار زماااااااااااان
          </h3>
        </SwiperSlide> */}
      </Swiper>
    </>
  );
}

export default Slider;
