import React, { useEffect } from "react";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import { Outlet, useLocation } from "react-router-dom";
import Whatsapp from "./whatsapp/Whatsapp";
import NavbarTop from "./Navbar/NavbarTop";

function RootLayout() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      <NavbarTop />
      <Navbar />
      <Outlet />
      <Footer />
      <Whatsapp />
    </>
  );
}

export default RootLayout;
