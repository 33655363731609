import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button, Input, message, Modal, Space, Spin, Upload } from "antd";
import {
  useEditArticleMutation,
  useEditOfferMutation,
  useGetFullArticleDetailsQuery,
} from "../../redux/Service/api";
import TextArea from "antd/es/input/TextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const EditArticle = ({
  setOpenEditModal,
  openEditModal,
  articleIdForEdit,
  rerenderEditKey,
}) => {
  const [modalKey, setModalKey] = useState(0);
  const [modalKey1, setModalKey1] = useState(0);

  const [authorName, setAuthorName] = useState("");
  const [authorNameEN, setAuthorNameEN] = useState("");
  const [content, setContent] = useState("");
  const [contentEN, setContentEN] = useState("");
  const [title, setTitle] = useState("");
  const [titleEN, setTitleEN] = useState("");

  const [file, setFile] = useState(null);
  const handleFileChange = (fileList) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const {
    data: allArticleData,
    isError: isAllArticleError,
    isLoading: isAllArticleLoading,
    error: allArticlerror,
  } = useGetFullArticleDetailsQuery(articleIdForEdit);

  const allArticleDetails = allArticleData?.data ?? null;

  useEffect(() => {
    setAuthorName(allArticleDetails?.author);
    setAuthorNameEN(allArticleDetails?.author_en);
    setContent(allArticleDetails?.content);
    setContentEN(allArticleDetails?.content_en);
    setTitle(allArticleDetails?.title);
    setTitleEN(allArticleDetails?.title_en);

    setFile([
      {
        uid: "-1",
        name: "Main-image",
        status: "done",
        url: allArticleDetails?.photo,
      },
    ]);

    setModalKey1((prev) => prev + 11);
  }, [allArticleDetails, rerenderEditKey]);

  const [
    EditArticle,
    { isLoading: isEditLoading, isError: isEditError, error },
  ] = useEditArticleMutation();

  const handleEditArticle = async () => {
    if (!file) {
      message.error("Please upload the file");
      return;
    }
    if (!title) {
      message.error("Please upload the title");
      return;
    }
    if (!content) {
      message.error("Please upload the content");
      return;
    }
    if (!authorName) {
      message.error("Please upload the author Name");
      return;
    }
    if (!titleEN) {
      message.error("Please upload the english title");
      return;
    }
    if (!contentEN) {
      message.error("Please upload the english content");
      return;
    }
    if (!authorNameEN) {
      message.error("Please upload english author Name");
      return;
    }
    const formData = new FormData();
    if (typeof file === "object" && !Array.isArray(file)) {
      formData.append("photo", file);
    }

    formData.append("title", title);
    formData.append("title_en", titleEN);
    formData.append("author", authorName);
    formData.append("author_en", authorNameEN);
    formData.append("content", content);
    formData.append("content_en", contentEN);
    try {
      const res = await EditArticle({
        id: articleIdForEdit,
        articleBody: formData,
      });
      setFile(null);
      setAuthorName("");
      setAuthorNameEN("");
      setTitle("");
      setTitleEN("");
      setContent("");
      setContentEN("");
      setModalKey((prev) => prev + 1);
      res.data.message
        ? message.info(res.data.message)
        : message.success("Offer added successfully");
      setOpenEditModal(false);
    } catch (error) {
      console.error("Error adding offer:", error);
      message.error("Error adding offer. Please try again.");
    }
  };

  return (
    <Modal
      key={modalKey}
      title="Edit Article"
      centered
      open={openEditModal}
      onCancel={() => setOpenEditModal(false)}
      width={1000}
      footer
    >
      {isAllArticleLoading && (
        <p>
          <Spin size="small" style={{ marginRight: "0.2rem" }} />
          loading details...
        </p>
      )}
      {isAllArticleError && <p className="text-danger">Something went error</p>}
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size="large"
        >
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column">
              <Input
                placeholder="عنوان المقالة"
                style={{ direction: "rtl", textAlign: "right" }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <Input
                placeholder="اسم المؤلف"
                style={{ direction: "rtl", textAlign: "right" }}
                value={authorName}
                onChange={(e) => setAuthorName(e.target.value)}
              />

              <TextArea
                rows={6}
                placeholder="الوصف"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                style={{ direction: "rtl", textAlign: "right" }}
              />
            </Col>
            <Col className="gap-3 d-flex flex-column">
              <Input
                placeholder="Title in english"
                value={titleEN}
                onChange={(e) => setTitleEN(e.target.value)}
              />
              <Input
                placeholder="author name in english"
                value={authorNameEN}
                onChange={(e) => setAuthorNameEN(e.target.value)}
              />

              <TextArea
                rows={6}
                placeholder="Content in english"
                value={contentEN}
                onChange={(e) => setContentEN(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column col-6">
              <Upload
                key={modalKey1}
                customRequest={({ file, onSuccess, onError }) => {
                  try {
                    onSuccess();
                  } catch (error) {
                    console.error("Error handling file:", error);
                  }
                }}
                onChange={({ fileList }) => {
                  handleFileChange(fileList);
                }}
                listType="picture"
                maxCount={1}
                defaultFileList={file}
              >
                <Button
                  loading={false}
                  icon={<FontAwesomeIcon icon={faDownload} />}
                >
                  Upload Image (Max: 1)
                </Button>
              </Upload>
            </Col>
          </Row>
          <Row className="g-2">
            <Button
              type="primary"
              onClick={handleEditArticle}
              loading={isEditLoading}
            >
              Edit the article
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default EditArticle;
