import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button, Input, message, Modal, Space, Spin, Upload } from "antd";
import {
  useEditNewsMutation,
  useGetFullNewsDetailsQuery,
} from "../../redux/Service/api";
import TextArea from "antd/es/input/TextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const EditNews = ({
  setOpenEditModal,
  openEditModal,
  articleIdForEdit,
  rerenderEditKey,
}) => {
  const [modalKey, setModalKey] = useState(0);
  const [modalKey1, setModalKey1] = useState(0);

  const [authorName, setAuthorName] = useState("");
  const [authorNameEN, setAuthorNameEN] = useState("");
  const [content, setContent] = useState("");
  const [contentEN, setContentEN] = useState("");
  const [title, setTitle] = useState("");
  const [titleEN, setTitleEN] = useState("");

  const [file, setFile] = useState(null);
  const handleFileChange = (fileList) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const {
    data: allNewsData,
    isError: isAllNewsError,
    isLoading: isAllNewsLoading,
    error: allArticlerror,
  } = useGetFullNewsDetailsQuery(articleIdForEdit);

  const allNewsDetails = allNewsData?.data ?? null;

  useEffect(() => {
    setAuthorName(allNewsDetails?.author);
    setAuthorNameEN(allNewsDetails?.author_en);
    setContent(allNewsDetails?.content);
    setContentEN(allNewsDetails?.content_en);
    setTitle(allNewsDetails?.title);
    setTitleEN(allNewsDetails?.title_en);

    setFile([
      {
        uid: "-1",
        name: "Main-image",
        status: "done",
        url: allNewsDetails?.photo,
      },
    ]);

    setModalKey1((prev) => prev + 11);
  }, [allNewsDetails, rerenderEditKey]);

  const [EditNews, { isLoading: isEditLoading, isError: isEditError, error }] =
    useEditNewsMutation();

  const handleEditNews = async () => {
    if (!file) {
      message.error("Please upload the file");
      return;
    }
    if (!title) {
      message.error("Please upload the title");
      return;
    }
    if (!content) {
      message.error("Please upload the content");
      return;
    }
    if (!authorName) {
      message.error("Please upload the author Name");
      return;
    }
    if (!titleEN) {
      message.error("Please upload the english title");
      return;
    }
    if (!contentEN) {
      message.error("Please upload the english content");
      return;
    }
    if (!authorNameEN) {
      message.error("Please upload english author Name");
      return;
    }
    const formData = new FormData();
    if (typeof file === "object" && !Array.isArray(file)) {
      formData.append("photo", file);
    }

    formData.append("title", title);
    formData.append("title_en", titleEN);
    formData.append("author", authorName);
    formData.append("author_en", authorNameEN);
    formData.append("content", content);
    formData.append("content_en", contentEN);
    try {
      const res = await EditNews({
        id: articleIdForEdit,
        articleBody: formData,
      });
      setFile(null);
      setAuthorName("");
      setAuthorNameEN("");
      setTitle("");
      setTitleEN("");
      setContent("");
      setContentEN("");
      setModalKey((prev) => prev + 1);
      res?.data?.message
        ? message.info(res.data.message)
        : message.success("News added successfully");
      setOpenEditModal(false);
    } catch (error) {
      console.error("Error adding News:", error);
      message.error("Error adding News. Please try again.");
    }
  };

  return (
    <Modal
      key={modalKey}
      title="Edit News"
      centered
      open={openEditModal}
      onCancel={() => setOpenEditModal(false)}
      width={1000}
      footer
    >
      {isAllNewsLoading && (
        <p>
          <Spin size="small" style={{ marginRight: "0.2rem" }} />
          loading details...
        </p>
      )}
      {isAllNewsError && <p className="text-danger">Something went error</p>}
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size="large"
        >
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column">
              <Input
                placeholder="عنوان المقالة"
                style={{ direction: "rtl", textAlign: "right" }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <Input
                placeholder="اسم المؤلف"
                style={{ direction: "rtl", textAlign: "right" }}
                value={authorName}
                onChange={(e) => setAuthorName(e.target.value)}
              />

              <TextArea
                placeholder="الوصف"
                rows={6}
                value={content}
                onChange={(e) => setContent(e.target.value)}
                style={{ direction: "rtl", textAlign: "right" }}
              />
            </Col>
            <Col className="gap-3 d-flex flex-column">
              <Input
                placeholder="Title in english"
                value={titleEN}
                onChange={(e) => setTitleEN(e.target.value)}
              />
              <Input
                placeholder="author name in english"
                value={authorNameEN}
                onChange={(e) => setAuthorNameEN(e.target.value)}
              />

              <TextArea
                placeholder="Content in english"
                value={contentEN}
                rows={6}
                onChange={(e) => setContentEN(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column col-6">
              <Upload
                key={modalKey1}
                customRequest={({ file, onSuccess, onError }) => {
                  try {
                    onSuccess();
                  } catch (error) {
                    console.error("Error handling file:", error);
                  }
                }}
                onChange={({ fileList }) => {
                  handleFileChange(fileList);
                }}
                listType="picture"
                maxCount={1}
                defaultFileList={file}
              >
                <Button
                  loading={false}
                  icon={<FontAwesomeIcon icon={faDownload} />}
                >
                  Upload Image (Max: 1)
                </Button>
              </Upload>
            </Col>
          </Row>
          <Row className="g-2">
            <Button
              type="primary"
              onClick={handleEditNews}
              loading={isEditLoading}
            >
              Edit the News
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default EditNews;
