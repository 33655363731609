import { faPenToSquare, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Flex, message, Modal, Row } from "antd";
import React, { useState } from "react";
import { Container } from "react-bootstrap";

import { DeleteFilled } from "@ant-design/icons";
import { ExclamationCircleFilled } from "@ant-design/icons";

import "./PhotoSliders.css";
import {
  useDeletePhotoSliderMutation,
  useGetAllPhotoSlidersQuery,
} from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import AddPhotoSlider from "./AddPhotoSlider";

const { Meta } = Card;
const { confirm } = Modal;

function PhotoSliders() {
  const [openAddModal, setOpenAddModal] = useState(false);

  const {
    data: allPhotoSlidersData,
    isError: isAllPhotoSlidersError,
    isLoading: isAllPhotoSlidersLoading,
    error: allPhotoSlidersError,
  } = useGetAllPhotoSlidersQuery();

  const allPhotoSliders = allPhotoSlidersData?.data ?? null;

  const [modal, contextHolder] = Modal.useModal();

  const [
    deletePhotoSlider,
    { isLoading: delIsLoading, isError: delIsError, error: delError },
  ] = useDeletePhotoSliderMutation();

  const handleDeleteCategory = async (id) => {
    confirm({
      title: "Do you really want to delete this Photo?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, this Photo will be removed ",
      async onOk() {
        try {
          const res = await deletePhotoSlider(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("Photo is deleted successfully");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };

  return (
    <section className=" section_padding mt-0 pt-0 position-relative">
      <h2 className="main_heading">Slider Photos</h2>
      {isAllPhotoSlidersLoading && <Loading />}
      {(isAllPhotoSlidersError || delIsError) && (
        <p className="text-danger">Something went error</p>
      )}
      {contextHolder}
      <Flex gap="small" wrap="nowrap" className="justify-content-center mb-5">
        <Button type="primary" onClick={() => setOpenAddModal(true)}>
          <FontAwesomeIcon icon={faPlus} /> Add Slider Photo{" "}
        </Button>
      </Flex>
      <Container className="my-5">
        <Row className="gap-5 justify-content-center">
          {allPhotoSliders?.map((photo) => (
            <Card
              key={photo.id}
              hoverable
              className="cat col-12 col-sm-6 col-md-3 col-lg-3"
              cover={
                <img
                  alt="slide-icon"
                  className="img_cat"
                  src={photo.photo}
                  style={{ maxHeight: "10rem", objectFit: "contain" }}
                />
              }
              actions={[
                <DeleteFilled
                  key="setting"
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    handleDeleteCategory(photo.id);
                  }}
                />,
              ]}
            >
              <div className=" position-relative"></div>
            </Card>
          ))}
        </Row>

        {/* add product */}
        <AddPhotoSlider
          setOpenAddModal={setOpenAddModal}
          openAddModal={openAddModal}
        />
      </Container>
    </section>
  );
}

export default PhotoSliders;
