import { Container, Image, Row } from "react-bootstrap";
import "./Articles.css";
// import logo from "../../assets/logo.jpg";
import logo from "../../assets/sargLogo.png";

import { useEffect } from "react";
import Aos from "aos";
import { useGetAllArticlesQuery } from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import { Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { language } from "../../language/Language";
import icon2 from "../../assets/icon2.png";
import title from "../../assets/title.png";

const Articles = () => {
  const navigate = useNavigate();
  const {
    data: allArticlesData,
    isError: isAllArticlesError,
    isLoading: isAllArticlesLoading,
    error: allArticlesError,
  } = useGetAllArticlesQuery();

  const allArticles = allArticlesData?.data ?? null;

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <section className="blog position-relative">
      {/* {inHome ? ( */}
      <div className="aboutUs pt-4 mb-3 d-flex justify-content-center align-items-center gap-md-5">
        {/* <h3 className="heading gap-3 d-flex align-items-center justify-content-center">
          {language === "en" ? "Various articles" : "مقالات متنوعه "}
          <Image className="col-2 rounded-5 heading" src={logo} />
        </h3> */}
        <div className="align-items-center justify-content-center p-0 image_headeing">
          <Image className="heading" src={icon2} />
        </div>
        <div className="d-flex align-items-center justify-content-center text_headeing ">
          <h3
            style={{
              backgroundImage: `url(${title})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain", // لضبط الصورة لتغطية العنصر بالكامل
              backgroundPosition: "center", // لضبط موضع الصورة في المنتصف
            }}
            className="mx-auto d-flex align-items-center justify-content-center headeingText main-color-mot-red"
          >
            {language === "en" ? "Various articles" : "مقالات متنوعه"}
          </h3>
        </div>
      </div>

      {isAllArticlesLoading && <Spin />}

      <main className="mt-lg-5">
        <Container>
          <Row>
            <section data-aos="fade-right new-light-bg-color">
              {allArticles?.map((article) => (
                <article
                  className="postcard new-light-bg-color"
                  key={article.id}
                >
                  <div className="new-light-bg-color">
                    <img
                      className="postcard__img my-3 new-light-bg-color"
                      src={article.photo}
                      alt="Title"
                    />
                  </div>
                  <div className="postcard__text t-dark new-light-bg-color">
                    <h1 className="postcard__title new-heading-color main-color-mot-red">
                      {article.title}
                    </h1>
                    {/* <div className="postcard__bar"></div> */}
                    {/* <div className="postcard__preview-txt">
                      <span className="userName">{article.author}</span>
                    </div> */}
                    <div className="">
                      <p className="author main-color-mot mt-2">
                        {article.content.slice(0, 400).trim()}...
                        <span
                          className="mx-1 main-color-mot-red"
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(`/Articles/${article.id}`)}
                        >
                          {language === "en" ? "more" : "المزيد"}
                        </span>
                      </p>
                    </div>
                  </div>
                </article>
              ))}
            </section>
            {isAllArticlesError && (
              <section className="light" data-aos="fade-left">
                <article className="postcard light blue new-light-bg-color">
                  <a className="postcard__img_link" href="#">
                    <img
                      className="postcard__img my-3"
                      src={logo}
                      alt="Image Title"
                    />
                  </a>
                  <div className="postcard__text t-dark ">
                    <h1 className="postcard__title blue">
                      <a href="#">
                        {" "}
                        {language === "en"
                          ? "United Pack Company"
                          : "الشركة المتحدة للأكياس يونيتد باك"}
                      </a>
                    </h1>
                    {/* <div className="postcard__bar"></div> */}
                    <div className="postcard__preview-txt">
                      <span className="userName">
                        {language === "en"
                          ? "United Pack Company"
                          : "الشركة المتحدة للأكياس يونيتد باك"}
                      </span>
                    </div>
                    <div className="">
                      <p className="author">
                        {language === "en"
                          ? "United Plastic Company's mission is to deliver top-quality plastic products and services that fulfill customer needs. The company is dedicated to ongoing innovation and improvement, aiming to be the premier provider of plastic products in Egypt and the Middle East."
                          : "مهمة شركة المتحده للبلاستيك هي تقديم منتجات وخدمات بلاستيكية عالية الجودة تلبي احتياجات عملائها. تلتزم الشركة بالابتكار والتحسين المستمر وتهدف إلى أن تكون المزود الرائد للمنتجات البلاستيكية في مصر ومنطقة الشرق الأوسط."}

                        <strong>
                          <Link to={""}>المزيد</Link>
                        </strong>
                      </p>
                    </div>
                  </div>
                </article>
              </section>
            )}
          </Row>
        </Container>
      </main>
    </section>
  );
};

export default Articles;
