import "./whatsapp.css";
import whats_app from "../../assets/whatsapp.png";
import { language } from "../../language/Language";

function Whatsapp() {
  // const language = localStorage.getItem("language") || "ar";

  return (
    // <!-- start whatsapp_massenger  -->
    <div className="whatsapp">
      <a
        href="https://api.whatsapp.com/send?phone=201005147716"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={whats_app} alt="" />
      </a>
      <a
        href="https://api.whatsapp.com/send?phone=201005147716"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>
          <strong>
            {" "}
            {/* {language == "ar" ? "تواصل معنا" : "contact us"} */}
            {language === "en" ? "Connect Us" : "تواصل معنا"}
          </strong>
        </p>
      </a>
    </div>
  );
}

export default Whatsapp;
