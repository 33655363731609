// import { Col, Container, Row } from "react-bootstrap";
// import { Image } from "antd";
import { useParams } from "react-router-dom";
import bg from "../../assets/Products1.jpg";
import Loading from "../../components/utils/Loading";
import { useGetOneArticleQuery } from "../../redux/Service/api";
import Linkify from "react-linkify";
import "./Article.css";
const ArticleDetails = () => {
  const { articleId } = useParams();
  const {
    data: articleData,
    isError: isArticleError,
    isLoading: isArticleLoading,
    error: articleError,
  } = useGetOneArticleQuery(articleId);

  const articleDetails = articleData?.data ?? null;

  const CustomLink = (href, text, key) => (
    <a href={href} target="_blank" rel="noopener noreferrer" key={key}>
      {text}
    </a>
  );

  return (
    <section
      className="position-relative pt-5 article mb-1 new-bg-color"
      style={{ minHeight: "50vh" }}
    >
      {isArticleLoading && <Loading />}
      {articleDetails && (
        <div className="two-thirds-image-left-one-third-text-right container pb-5">
          <div className="image">
            <img
              src={articleDetails.photo}
              style={{ maxHeight: "25rem" }}
              className=" rounded-3"
              alt=""
            />
          </div>
          <div className="text">
            <h3 className="title new-heading-color">{articleDetails.title}</h3>
            <span className="lead">{articleDetails.author}</span>
            {/* <p className="content new-text-color">{articleDetails.content}</p> */}
            <p className="content new-text-color">
              <Linkify componentDecorator={CustomLink}>
                {articleDetails.content}
              </Linkify>{" "}
            </p>
          </div>
        </div>
      )}
      {isArticleError && (
        <div className="two-thirds-image-left-one-third-text-right container">
          <div className="image">
            <img src={bg} alt="" />
          </div>
          <div className="text">
            <h3 className="title">
              7 ملايين دولار حصة مصر من تجارة المنتجات الجلدية على مستوى العالم
            </h3>
            <span className="lead">محمود سرج</span>
            <p className="content">
              قال المهندس محمود سرج رئيس المجلس التصديري للصناعات الجلدية إن حجم
              تداول تجارة المنتجات الجلدية على مستوى العالم يبلغ نحو 600 مليار
              دولار فى حين أن حصتنا منها تبلغ نحو 7 مليون دولار فقط، لافتاً إلى
              أننا نحتاج الابتكارات وتطوير قطاع الموضة للنهوض بالصادرات المحلية.
              وأضاف خلال كلمته في مؤتمر “شبكة الابتكار والتعاون الأورومتوسطية
              لقطاعات النسيج والجلود”، أن اليوم، وبشراكة تامة للحكومة والقطاع
              الخاص، وبدعم من الاتحاد الأوروبي في إطار مشروع cre@ctive والممول
              من برنامج التعاون عبر الحدود ENI-CBC-MED هناك جهود تسعى لوجود
              علامة تجارية كبيرة لمصر في مجال المنتجات الجلدية ونحتاج للوصول
              لهذا الأمر تطوير الموضة وتطوير شراكات مع البراندات العالمية.
              لمتابعة آخر الأخبار عبر Google News وأوضح رئيس المجلس التصديري
              للصناعات الجلدية أن الآن أصبح لدينا مصانع لديها الشهادة الرئيسية
              التي يتطلبها التعاون مع أي اسم تجاري عالمي. واعتبر سرج أننا نحتاج
              أن يتم وضعنا على أول الطريق حتى نبدأ العمل بما نمتلكه من إمكانات،
              لافتاً إلى أن مصر لديها مميزات تشمل العمالة والكهرباء الرخيصة
              وتكاليف منخفضة، فضلاً عن أن هناك اتفاقات كبيرة مع دول عربية
              وأفريقيا وأمريكا الجنوبية مما يمكننا من وجود محور في مصر لكن نحتاج
              بعض الدعم للتصنيع. وشدد رئيس المجلس التصديري للصناعات الجلدية على
              أن لدينا موقع جغرافي لجذب استثمارات كانت توجه لفيتنام أو كمبوديا
              أو غيرها، لكننا نحتاج تحديث الموضة وتدريب العمال، رغم أنها أسهل من
              توفير الموضة. وأشار إلى أن الرئيس عبد الفتاح السيسي حقق حلم
              الانتقال إلى منطقة الروبيكي لصناعة الجلود، لافتاً إلى أن المنطقة
              وشملت 36 مصنع لمستلزمات المنتجات الجلدية.
            </p>
          </div>
        </div>
      )}
    </section>
  );
};

export default ArticleDetails;
