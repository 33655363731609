import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Modal, Space, message, Button, Input } from "antd";
import { useAddJobRequestMutation } from "../../redux/Service/api";
import TextArea from "antd/es/input/TextArea";
import { language } from "../../language/Language";
const ApplyJob = ({ setOpenAddModal, openAddModal, jobIdForApply }) => {
  const [modalKey, setModalKey] = useState(0);

  const [name, setName] = useState("");
  const [qualification, setQualification] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [experience, setExperience] = useState("");

  //

  const [applyJob, { isLoading, isError, error }] = useAddJobRequestMutation();

  const handleAddJob = async () => {
    if (!name) {
      message.error("Please add the Name");
      return;
    }
    if (!qualification) {
      message.error("Please add the qualification");
      return;
    }
    if (!address) {
      message.error("Please add the address");
      return;
    }
    if (!phone) {
      message.error("Please add the phone");
      return;
    }
    if (!experience) {
      message.error("Please add the experience");
      return;
    }

    const requestBody = {
      job_id: jobIdForApply,
      name,
      qualification,
      address,
      phone,
      experience,
    };

    try {
      const res = await applyJob(requestBody);

      setName("");
      setAddress("");
      setQualification("");
      setPhone("");
      setExperience("");

      setModalKey((prev) => prev + 1);
      res.data.message
        ? message.info(res.data.message)
        : message.success("Job request added successfully");
      setOpenAddModal(false);
    } catch (error) {
      console.error("Error adding Job request :", error);
      message.error("Error adding Job request. Please try again.");
    }
  };

  return (
    <Modal
      key={modalKey}
      title={language === "en" ? "Employment application" : "طلب التوظيف"}
      // centered
      open={openAddModal}
      onCancel={() => setOpenAddModal(false)}
      width={600}
      footer
      style={{ direction: "rtl", textAlign: "right" }}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size="large"
        >
          <Row className="g-2">
            <Input
              size="large"
              placeholder={language === "en" ? "the name" : "الاسم"}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              size="large"
              placeholder={language === "en" ? "the address" : "العنوان"}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <Input
              size="large"
              placeholder={language === "en" ? "the phone" : "الهاتف"}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <Input
              size="large"
              placeholder={language === "en" ? "Qualifications" : "المؤهلات"}
              value={qualification}
              onChange={(e) => setQualification(e.target.value)}
            />
            <TextArea
              placeholder={language === "en" ? "Experience" : "الخبرة"}
              value={experience}
              onChange={(e) => setExperience(e.target.value)}
            />
          </Row>

          <Row className="g-2">
            <Button type="primary" onClick={handleAddJob} loading={isLoading}>
              {language === "en" ? "Presentation" : "تقديم"}
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default ApplyJob;
