import { Spin } from "antd";
import React from "react";
import { Container } from "react-bootstrap";
import profile_admin from "../../assets/sargLogo.png";
import profile_admin2 from "../../assets/sargLogo.png";
import "./Profile.css";
import { language } from "../../language/Language";
import { useSelector } from "react-redux";
function Profile({ allAbouts, isAllAboutsError, isAllAboutsLoading }) {
  const { mode } = useSelector((st) => st.DarkMode);

  return (
    <Container className="d-flex me-xl-5 aboutUs_content flex-wrap justify-content-center justify-content-lg-start align-items-center mt-4 mb-5 shadow rounded gap-3 gap-lg-0">
      <div className="home__image">
        {allAbouts?.map((about, idx) => (
          <img
            style={{ objectFit: "fill" }}
            key={idx}
            src={about.photo}
            className="rounded-3"
            alt=""
          ></img>
        ))}
        {isAllAboutsError && (
          <img
            src={mode ? profile_admin : profile_admin2}
            className=" rounded-3"
            alt=""
          ></img>
        )}
      </div>
      {isAllAboutsLoading && <Spin />}
      {allAbouts?.map((about) => (
        <div className="home__content col-lg-8 py-4" key={about.id}>
          <div className=" language_about mx-4">
            <p className="my-3 title-profile fw-bold main-color-mot">
              {about.manager_name}
            </p>
            <h3 className="main-color-mot title-profile fw-bold">
              {about.manager_position}
            </h3>
            <p
              className="main-color-mot"
              style={{
                lineHeight: 1.7,
                fontWeight: "700",
              }}
            >
              {about.manager_information.slice(0, 300).trim()}...
            </p>
          </div>
        </div>
      ))}
      {isAllAboutsError && (
        <div className="home__content mx-xl-5">
          <div className="text-center text-lg-end">
            <p className="main-color-mot mb-3 fs-4 fw-bold">
              {language === "en" ? "About Us" : "من نحن"}
            </p>
            {/* <h3 className="new-text-color fw-bold">
              {language === "en"
                ? "Chairman of the Board of Directors of Sarj Company"
                : "رئيس مجلس إدارة شركة سرج"}
            </h3> */}
            <p className="main-color-mot fs-5 fw-bold">
              {language === "en"
                ? "United Plastic Company's mission is to provide high quality plastic products and services that meet the needs of its customers. The company is committed to continuous innovation and improvement and strives to be the leading provider of plastic products in Egypt and the Middle East region."
                : "تركز مهمة شركة المتحده  للبلاستيك على توفير منتجات وخدمات بلاستيكية عالية الجودة تلبي احتياجات عملائها. كما تلتزم الشركة بالابتكار والتحسين المستمر وتسعى جاهدة لتكون المزود الرئيسي للمنتجات البلاستيكية في مصر ومنطقة الشرق الأوسط"}
            </p>
          </div>
        </div>
      )}
    </Container>
  );
}

export default Profile;
