import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import "./SliderPhotos.css";

// img

import Products1 from "../../assets/sargLogo.png";
import { Image } from "react-bootstrap";
import Aos from "aos";
import { useGetAllPhotoSlidersQuery } from "../../redux/Service/api";
import { Spin } from "antd";

function SliderPhotos() {
  const {
    data: allPhotoSlidersData,
    isError: isAllPhotoSlidersError,
    isLoading: isAllPhotoSlidersLoading,
    error: allPhotoSlidersError,
  } = useGetAllPhotoSlidersQuery();

  const allPhotoSliders = allPhotoSlidersData?.data ?? null;

  useEffect(() => {
    Aos.init({ duration: 2000 }); // تهيئة AOS وتحديد مدة الانتقال الافتراضية
  }, []);

  return (
    <div className="my-lg-5 SliderPhotos" data-aos="fade-up">
      <Swiper
        watchSlidesProgress={true}
        slidesPerGroup={1}
        loop={true}
        autoplay={{
          delay: 3000,
          pauseOnMouseEnter: false,
          disableOnInteraction: false,
        }}
        // loopFillGroupWithBlank={true}
        modules={[Autoplay, Pagination]}
        breakpoints={{
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
        }}
        slidesPerView={1.3}
        spaceBetween={10}
        className="mySwiper w-100 pb-lg-5 pt-lg-3"
      >
        {allPhotoSliders?.map((item) => (
          <SwiperSlide key={item.id}>
            <Image src={item.photo} className="sldier_img" />
          </SwiperSlide>
        ))}
        {isAllPhotoSlidersLoading && <Spin />}
        {isAllPhotoSlidersError && (
          <>
            <SwiperSlide>
              <Image src={Products1} className="sldier_img" />
            </SwiperSlide>
          </>
        )}

        {/* <SwiperSlide>
          <Image src={Products2} className="sldier_img" />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={Products3} className="sldier_img" />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={Products4} className="sldier_img" />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={Products5} className="sldier_img" />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={Products6} className="sldier_img" />
        </SwiperSlide> */}
      </Swiper>
    </div>
  );
}

export default SliderPhotos;
