import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://united.prosofteg.com/",
  prepareHeaders: (headers, { endpoint }) => {
    const token = localStorage.getItem("unitedUserToken");
    const language = localStorage.getItem("language");

    // Default headers
    headers.set("Accept-Language", language ? language : "ar");
    headers.set("Accept", "application/json");

    // Include token for all endpoints except "login" and "register"
    if (token && endpoint !== "login" && endpoint !== "register") {
      headers.set("Authorization", `Bearer ${token}`);
    }

    // const endpointsWithMultipart = ["editOffer", "otherEndpoint"];
    // if (endpointsWithMultipart.includes(endpoint)) {
    //   headers.set("Content-Type", "multipart/form-data");
    // }

    return headers;
  },
});

export const api = createApi({
  reducerPath: "api",
  baseQuery,
  endpoints: (builder) => ({
    // ******** User ********* //
    login: builder.mutation({
      query: (loginData) => ({
        url: "api/auth/login",
        method: "POST",
        body: loginData,
      }),
      invalidatesTags: ["User"],
      onError: (error) => {
        console.error("Login error:", error);
      },
    }),
    logout: builder.mutation({
      query: () => ({
        url: "api/auth/logout",
        method: "POST",
      }),
      invalidatesTags: ["User"],
    }),

    getLogedUser: builder.query({
      query: () => "api/auth/show",
      providesTags: ["User"],
    }),

    updateUserData: builder.mutation({
      query: (userData) => ({
        url: "api/auth/update",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["User"],
      onError: (error) => {
        console.error("Login error:", error);
      },
    }),
    // **************** Categories ************** //

    getAllCategories: builder.query({
      query: () => "api/items",
      providesTags: ["items"],
    }),
    // **************** photoSliders ************** //

    getAllPhotoSliders: builder.query({
      query: () => "api/photoSliders",
      providesTags: ["photoSliders"],
    }),
    // **************** banners ************** //

    getAllBanners: builder.query({
      query: () => "api/banners",
      providesTags: ["banners"],
    }),

    // **************** Home page Title Sliders **************** //
    getSlides: builder.query({
      query: () => "api/sliders",
      providesTags: ["sliders"],
    }),

    // **************** articles **************** //
    getAllArticles: builder.query({
      query: () => "api/posts",
      providesTags: ["articles"],
    }),
    getOneArticle: builder.query({
      query: (id) => `api/posts/${id}`,
      providesTags: ["articles"],
    }),
    // **************** News **************** //
    getAllNews: builder.query({
      query: () => "api/news",
      providesTags: ["news"],
    }),
    getOneNews: builder.query({
      query: (id) => `api/news/${id}`,
      providesTags: ["news"],
    }),
    // **************** Tips **************** //
    getAllTips: builder.query({
      query: () => "api/hints",
      providesTags: ["tips"],
    }),
    // **************** Jobs **************** //
    getAllJobs: builder.query({
      query: () => "api/jobs",
      providesTags: ["jobs", "jobRequests"],
    }),
    // **************** Job Requests**************** //
    getAllJobRequests: builder.query({
      query: () => "api/requestjobs",
      providesTags: ["jobs", "jobRequests"],
    }),
    // **************** About **************** //
    getAllAbouts: builder.query({
      query: () => "api/abouts",
      providesTags: ["abouts"],
    }),

    // ******************************************* Dashboard ***************************************** //
    // Home page Title Sliders
    uploadSlider: builder.mutation({
      query: (formData) => ({
        url: "api/sliders",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["sliders"],
    }),
    deleteSlider: builder.mutation({
      query: (sliderId) => ({
        url: `api/sliders/${sliderId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["sliders"],
    }),

    // Users
    createAdmin: builder.mutation({
      query: (userData) => ({
        url: "api/admin/store",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["User"],
      onError: (error) => {
        console.error("Registration error:", error);
      },
    }),
    adminGetAllUser: builder.query({
      query: () => "api/admin?role=all",
      providesTags: ["User"],
    }),

    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `api/admin/delete/${userId}`,
        method: "POST",
      }),
      invalidatesTags: ["User"],
    }),
    swapUserRole: builder.mutation({
      query: (userId) => ({
        url: `api/admin/swap/${userId}`,
        method: "POST",
      }),
      invalidatesTags: ["User"],
    }),

    // Category
    addCategory: builder.mutation({
      query: (formData) => {
        return {
          url: "api/items",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["items"],
    }),
    deleteCategory: builder.mutation({
      query: (catId) => ({
        url: `api/items/${catId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["items"],
    }),
    editCategory: builder.mutation({
      query: ({ id, categoryBody }) => {
        return {
          url: `api/items/${id}`,
          method: "POST",
          body: categoryBody,
        };
      },
      invalidatesTags: ["items"],
    }),
    // photoSliders
    addPhotoSlider: builder.mutation({
      query: (formData) => {
        return {
          url: "api/photoSliders",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["photoSliders"],
    }),
    deletePhotoSlider: builder.mutation({
      query: (photoId) => ({
        url: `api/photoSliders/${photoId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["photoSliders"],
    }),
    // banners
    addBanner: builder.mutation({
      query: (formData) => {
        return {
          url: "api/banners",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["banners"],
    }),
    deleteBanner: builder.mutation({
      query: (bannersId) => ({
        url: `api/banners/${bannersId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["banners"],
    }),
    // Articles
    addArticle: builder.mutation({
      query: (articleBody) => {
        return {
          url: "api/posts",
          method: "POST",
          body: articleBody,
        };
      },
      invalidatesTags: ["articles"],
    }),
    deleteArticle: builder.mutation({
      query: (tipId) => ({
        url: `api/posts/${tipId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["articles"],
    }),
    getFullArticleDetails: builder.query({
      query: (id) => `api/posts/${id}/edit`,
      providesTags: ["articles"],
    }),
    editArticle: builder.mutation({
      query: ({ id, articleBody }) => {
        return {
          url: `api/posts/${id}`,
          method: "POST",
          body: articleBody,
        };
      },
      invalidatesTags: ["articles"],
    }),
    // News
    addNews: builder.mutation({
      query: (articleBody) => {
        return {
          url: "api/news",
          method: "POST",
          body: articleBody,
        };
      },
      invalidatesTags: ["news"],
    }),
    deleteNews: builder.mutation({
      query: (tipId) => ({
        url: `api/news/${tipId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["news"],
    }),
    getFullNewsDetails: builder.query({
      query: (id) => `api/news/${id}/edit`,
      providesTags: ["news"],
    }),
    editNews: builder.mutation({
      query: ({ id, articleBody }) => {
        return {
          url: `api/news/${id}`,
          method: "POST",
          body: articleBody,
        };
      },
      invalidatesTags: ["news"],
    }),
    // Jobs
    addJob: builder.mutation({
      query: (tipBody) => {
        return {
          url: "api/jobs",
          method: "POST",
          body: tipBody,
        };
      },
      invalidatesTags: ["jobs", "jobRequests"],
    }),
    deleteJob: builder.mutation({
      query: (tipId) => ({
        url: `api/jobs/${tipId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["jobs", "jobRequests"],
    }),
    getFullJobDetails: builder.query({
      query: (id) => `api/jobs/${id}/edit`,
      providesTags: ["jobs", "jobRequests"],
    }),
    editJob: builder.mutation({
      query: ({ id, jobBody }) => {
        return {
          url: `api/jobs/${id}`,
          method: "POST",
          body: jobBody,
        };
      },
      invalidatesTags: ["jobs", "jobRequests"],
    }),
    // Job Requests
    addJobRequest: builder.mutation({
      query: (requestBody) => {
        return {
          url: "api/requestjobs",
          method: "POST",
          body: requestBody,
        };
      },
      invalidatesTags: ["jobs", "jobRequests"],
    }),
    deleteJobRequest: builder.mutation({
      query: (requestId) => ({
        url: `api/requestjobs/${requestId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["jobs", "jobRequests"],
    }),
    // Abouts
    getFullAboutDetails: builder.query({
      query: (id) => `api/abouts/${id}/edit`,
      providesTags: ["abouts"],
    }),
    editAbout: builder.mutation({
      query: ({ id, jobBody }) => {
        return {
          url: `api/abouts/${id}`,
          method: "POST",
          body: jobBody,
        };
      },
      invalidatesTags: ["abouts"],
    }),
  }),
});

export const {
  // user
  useLoginMutation,
  useGetLogedUserQuery,
  useLogoutMutation,
  useUpdateUserDataMutation,
  useAdminGetAllUserQuery,
  // categories
  useGetAllCategoriesQuery,
  // PhotoSliders
  useGetAllPhotoSlidersQuery,
  // banners
  useGetAllBannersQuery,
  // Sliders
  useGetSlidesQuery,
  // Articles
  useGetAllArticlesQuery,
  useGetOneArticleQuery,
  // News
  useGetAllNewsQuery,
  useGetOneNewsQuery,
  // Tips
  useGetAllJobsQuery,
  // Job Requests
  useGetAllJobRequestsQuery,
  // About
  useGetAllAboutsQuery,

  //*********** Dashboards ************* //
  // users
  useDeleteUserMutation,
  useSwapUserRoleMutation,
  useCreateAdminMutation,
  useEditCategoryMutation,
  // sliders
  useUploadSliderMutation,
  useDeleteSliderMutation,
  // categories
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  // PhotoSlider
  useAddPhotoSliderMutation,
  useDeletePhotoSliderMutation,
  // banners
  useAddBannerMutation,
  useDeleteBannerMutation,
  // articles
  useAddArticleMutation,
  useDeleteArticleMutation,
  useGetFullArticleDetailsQuery,
  useEditArticleMutation,
  // News
  useAddNewsMutation,
  useDeleteNewsMutation,
  useEditNewsMutation,
  useGetFullNewsDetailsQuery,
  // Jobs
  useAddJobMutation,
  useDeleteJobMutation,
  useEditJobMutation,
  useGetFullJobDetailsQuery,
  // Job Requests
  useAddJobRequestMutation,
  useDeleteJobRequestMutation,
  // Abouts
  useGetFullAboutDetailsQuery,
  useEditAboutMutation,
} = api;
