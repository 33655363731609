import { faPenToSquare, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeleteFilled } from "@ant-design/icons";
import { ExclamationCircleFilled } from "@ant-design/icons";

import { Button, Card, Flex, Image, message, Modal, Row } from "antd";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useGetAllAboutsQuery } from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import EditAbout from "./EditAbout";

const { Meta } = Card;

function AboutControl() {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [rerenderEditKey, setRerenderEditKey] = useState(0);
  const [tipIdForEdit, setTipIdForEdit] = useState(null);

  const [modal, contextHolder] = Modal.useModal();

  const {
    data: allAboutsData,
    isError: isAllAboutsError,
    isLoading: isAllAboutsLoading,
    error: allAboutsError,
  } = useGetAllAboutsQuery();

  const allAbouts = allAboutsData?.data ?? null;
  console.log("allAbouts", allAbouts);
  return (
    <section
      className=" section_padding mt-0 pt-0 position-relative"
      style={{ color: "black" }}
    >
      <h2 className="main_heading"> About United Plastic</h2>
      {isAllAboutsLoading && <Loading />}
      {isAllAboutsError && <p className="text-danger">Something went error</p>}
      {contextHolder}
      <Container className="my-5">
        <Row className="gap-5 justify-content-center  fs-5">
          {allAbouts?.map((about) => (
            <div
              key={about.id}
              // className="col-12 col-md-5 col-xl-3 border-2"
            >
              <div className="d-flex gap-1">
                <span style={{ textWrap: "nowrap", color: "green" }}>
                  من هو:{" "}
                </span>
                <p>{about.manager_name}</p>
              </div>
              <div className="d-flex gap-1">
                <span style={{ textWrap: "nowrap", color: "green" }}>
                  وظيفته:{" "}
                </span>
                <p>{about.manager_position}</p>
              </div>
              <div className="d-flex gap-1">
                <span style={{ textWrap: "nowrap", color: "green" }}>
                  معلومات اضافية:{" "}
                </span>
                <p>{about.manager_information}</p>
              </div>
              <div className="d-flex gap-1">
                <span style={{ textWrap: "nowrap", color: "green" }}>
                  عن سرج:{" "}
                </span>
                <p>{about.about_us}</p>
              </div>

              <div className="d-flex flex-column gap-1">
                <span style={{ textWrap: "nowrap", color: "green" }}>
                  الصورة:{" "}
                </span>
                <img
                  src={about.photo}
                  alt="imag"
                  className="rounded-3"
                  style={{ width: "15rem" }}
                />
              </div>
              <div className="d-flex flex-column gap-1">
                <span style={{ textWrap: "nowrap", color: "green" }}>
                  الفيديو:{" "}
                </span>
                <div className="col-12 col-lg-6 videoAbout rounded-1">
                  <iframe
                    src={about.video_link}
                    title="about video"
                    className="w-100"
                    // width="100%"
                    height="300"
                    style={{
                      border: "none",
                      overflow: "hidden",
                      borderRadius: "1rem",
                    }}
                    allowFullScreen={true}
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  ></iframe>
                </div>
              </div>
              <div className="w-100 d-flex justify-content-center">
                <Button
                  type="primary"
                  onClick={() => {
                    setTipIdForEdit(about.id);
                    setOpenEditModal(true);
                    setRerenderEditKey((prev) => prev + 1);
                  }}
                >
                  تعديل{" "}
                  <FontAwesomeIcon
                    style={{ fontSize: "20px" }}
                    icon={faPenToSquare}
                  />
                </Button>
              </div>
            </div>
          ))}
        </Row>

        <EditAbout
          setOpenEditModal={setOpenEditModal}
          openEditModal={openEditModal}
          tipIdForEdit={tipIdForEdit}
          rerenderEditKey={rerenderEditKey}
        />
      </Container>
    </section>
  );
}

export default AboutControl;
