import "./Connect.css";
import { language } from "../../language/Language";
function Connect() {
  return (
    <article className="contact my-5 new-bg-color">
      <article className="container contact_container">
        <form action="" className="form_contact">
          <span>
            {language === "en" ? "Leave your message" : "اترك رسالتك"}
          </span>
          <h2>
            {language === "en"
              ? "We would love to hear from you"
              : "نحن نحب أن نسمع منك"}
          </h2>
          <input
            id="name"
            type="text"
            placeholder={language === "en" ? "Name" : "الاسم"}
          />
          <input
            id="email"
            type="text"
            placeholder={language === "en" ? "E-mail" : "البريد الإلكتروني"}
          />
          <input
            id="subject"
            type="text"
            placeholder={language === "en" ? "Theme" : "موضوع"}
          />
          <textarea
            id="text"
            name=""
            cols="30"
            rows="7"
            placeholder={
              language === "en" ? "Enter your message" : "ادخل رسالتك"
            }
          ></textarea>
          <button className="normal but_email btn col-12 ">
            {language === "en" ? "Send" : "ارسل"}
          </button>
        </form>
      </article>
    </article>
  );
}

export default Connect;
