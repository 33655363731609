import React from "react";
import Slider from "../../Sections/Slider/Slider";
import { Container } from "react-bootstrap";
import AboutUs from "../../Sections/AboutUs/AboutUs";
import Articles from "../../Sections/Articles/Articles";
// import Products from '../../components/Products/Products'
import SomeProducts from "../../Sections/SomeProducts/SomeProducts";
import SaddleNews from "../../Sections/SaddleNews/SaddleNews";
import Profile from "../../Sections/Profile/Profile";
import SliderPhotos from "../../Sections/SliderPhotos/SliderPhotos";
import "./Home.css";

function Home() {
  return (
    <>
      <Slider />
      <Container className="my-3 my-md-5">
        <AboutUs />
        <SomeProducts />
        <SaddleNews />
        <Articles />
        <SliderPhotos />
      </Container>
    </>
  );
}

export default Home;
