import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Modal, Space, message, Button, Input } from "antd";
import { useCreateAdminMutation } from "../../redux/Service/api";

const AddAdmin = ({ setOpenAddModal, openAddModal }) => {
  const [modalKey, setModalKey] = useState(0);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  //

  const [addAdmin, { isLoading, isError, error }] = useCreateAdminMutation();

  const handleAddAdmin = async () => {
    if (!name) {
      message.error("Please add the Name");
      return;
    }
    if (!email) {
      message.error("Please add the qualification");
      return;
    }
    if (!password) {
      message.error("Please add the address");
      return;
    }
    if (!password.match(/^(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
      setPasswordError(
        "password must be at least 8 characters and at least contains one lower and one upper case letters"
      );
      return;
    }

    const requestBody = {
      name,
      email,
      password,
      role: "admin",
    };

    try {
      const res = await addAdmin(requestBody);

      setName("");
      setEmail("");
      setPassword("");
      setPasswordError("");

      setModalKey((prev) => prev + 1);
      res.data.message
        ? message.info(res.data.message)
        : message.success("Admin added successfully");
      setOpenAddModal(false);
    } catch (error) {
      console.error("Error adding Admin :", error);
      message.error("Error adding Admin. Please try again.");
    }
  };

  return (
    <Modal
      key={modalKey}
      title="create Admin"
      // centered
      open={openAddModal}
      onCancel={() => setOpenAddModal(false)}
      width={500}
      footer
      style={{ direction: "ltr" }}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size="large"
        >
          <Row className="g-2">
            <Input
              size="large"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              size="large"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              size="large"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <p className=" text-danger">{passwordError && passwordError}</p>
          </Row>

          <Row className="g-2">
            <Button type="primary" onClick={handleAddAdmin} loading={isLoading}>
              create
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default AddAdmin;
